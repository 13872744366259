import {Grid, Link, Typography} from "@mui/material"
import React from "react"
import appArchiveIcon112 from "../image/AppArchiveIcon-1.12.png"
import appArchiveIcon from "../image/AppArchiveIcon.png"

export const OldVersionsSection = () => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center" spacing="1rem">
        <Typography variant="h4" gutterBottom>Download older versions</Typography>

        <Grid item>
            <Grid container flexDirection="column" alignItems="left" textAlign="left" spacing="1rem">
                <Grid item>
                    <Link href={`${process.env.PUBLIC_URL}/download/StandUp 2.7.dmg`} download>
                        <img src={appArchiveIcon} alt="StandUp App 2.7" width="64px" height="64px"
                             style={{marginRight: "1rem", verticalAlign: "middle"}}/>
                        StandUp 2.7 for macOS 13 (Ventura) and newer
                    </Link>
                </Grid>

                <Grid item>
                    <Link href={`${process.env.PUBLIC_URL}/download/StandUp 2.5.dmg`} download>
                        <img src={appArchiveIcon} alt="StandUp App 2.5" width="64px" height="64px"
                             style={{marginRight: "1rem", verticalAlign: "middle"}}/>
                        StandUp 2.5 for macOS 12 (Monterey)
                    </Link>
                </Grid>

                <Grid item>
                    <Link href={`${process.env.PUBLIC_URL}/download/StandUp 2.4.dmg`} download>
                        <img src={appArchiveIcon} alt="StandUp App 2.4" width="64px" height="64px"
                             style={{marginRight: "1rem", verticalAlign: "middle"}}/>
                        StandUp 2.4 for macOS 10.15 (Catalina) and 11 (Big Sur)
                    </Link>
                </Grid>

                <Grid item>
                    <Link href={`${process.env.PUBLIC_URL}/download/StandUp 1.12.dmg`} download>
                        <img src={appArchiveIcon112} alt="StandUp App 1.12" width="64px" height="64px"
                             style={{marginRight: "1rem", verticalAlign: "middle"}}/>
                        StandUp 1.12 for macOS 10.13 (High Sierra) and 10.14 (Mojave)
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)
