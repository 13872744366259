import {Button, Grid, Typography} from "@mui/material"
import React from "react"

export const DonateSection = () => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center">
        <Typography variant="h4" gutterBottom>Donate</Typography>

        <Typography gutterBottom>
            If you love our app, please consider a donation. <br/>Any amount will help us maintain the application.
        </Typography>

        <Button variant="outlined" color="warning" target="_blank" rel="noreferrer" sx={{marginY: "1rem"}}
                href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=XWFEGKCSR8Z32&currency_code=USD&source=url">
            Donate
        </Button>
    </Grid>
)
