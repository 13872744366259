import {Container, Grid} from "@mui/material"
import React, {useEffect, useRef} from "react"
import {AdviceSection} from "./section/AdviceSection"
import {AppInfoSection} from "./section/AppInfoSection"
import {ContactUsSection} from "./section/ContactUsSection"
import {DonateSection} from "./section/DonateSection"
import {FAQSection} from "./section/FAQSection"
import {FooterSection} from "./section/FooterSection"
import {LongTermSittingSection} from "./section/LongTermSittingSection"
import {MotivationSection} from "./section/MotivationSection"
import {OldVersionsSection} from "./section/OldVersionsSection"
import {SittingIssueSection} from "./section/SittingIssueSection"
import {SpineDetailsSection} from "./section/SpineDetailsSection"

export function App() {
    const appInfoId = "StandUp"
    const adviceId = "advice"
    const straightPostureId = "straightPosture"

    const contactUsRef = useRef<HTMLDivElement>(null)
    const privacyRef = useRef<HTMLDivElement>(null)

    // Needed for App Store link to scroll to the contact and privacy sections
    const anchorRefs: [string, React.RefObject<HTMLElement>][] = [
        ["#contactUs", contactUsRef],
        ["#privacy", privacyRef],
    ]

    useEffect(() => {
        scrollToAnchor(window.location.hash)
    })

    return (
        <Container maxWidth="md" sx={{paddingY: "3em"}}>
            <Grid container flexDirection="column" textAlign="center" spacing="2rem">
                <Grid item>
                    <AppInfoSection appInfoId={appInfoId}/>
                </Grid>

                <Grid item>
                    <SittingIssueSection straightPostureId={straightPostureId}/>
                </Grid>

                <Grid item>
                    <LongTermSittingSection/>
                </Grid>

                <Grid item>
                    <AdviceSection appInfoId={appInfoId} straightPostureId={straightPostureId} adviceId={adviceId}/>
                </Grid>

                <Grid item>
                    <SpineDetailsSection/>
                </Grid>

                <Grid item>
                    <MotivationSection appInfoId={appInfoId} adviceId={adviceId}/>
                </Grid>

                <Grid item>
                    <FAQSection/>
                </Grid>

                <Grid item ref={contactUsRef}>
                    <ContactUsSection/>
                </Grid>

                <Grid item>
                    <DonateSection/>
                </Grid>

                <Grid item>
                    <OldVersionsSection/>
                </Grid>

                <Grid item ref={privacyRef}>
                    <FooterSection/>
                </Grid>
            </Grid>
        </Container>
    )

    function scrollToAnchor(anchor?: string) {
        if (!anchor) return

        const linkRef = anchorRefs.find(([item]) => item === anchor)
        linkRef && linkRef[1].current?.scrollIntoView()
    }
}
