import {createTheme, PaletteColorOptions} from "@mui/material"

// module augmentation for TypeScript to allow custom palettes
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
declare module "@mui/material/styles/createPalette" {
    interface Palette {
        cardBorder: PaletteColorOptions
        highlighted: PaletteColorOptions
        title: PaletteColorOptions
    }
    interface PaletteOptions {
        cardBorder: PaletteColorOptions
        highlighted: PaletteColorOptions
        title: PaletteColorOptions
    }
}

const augmentColor = createTheme().palette.augmentColor

const backgroundColor = "#343a40"
const cardBorderColor = augmentColor({color: {main: "#808080"}})
const highlightedColor = augmentColor({color: {main: "#ffa500"}})
const titleColor = augmentColor({color: {main: "#e2ac6d"}})

export const globalTheme = createTheme({
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            "\"Segoe UI\"",
            "Roboto",
            "\"Helvetica Neue\"",
            "Arial",
            "sans-serif",
            "\"Apple Color Emoji\"",
            "\"Segoe UI Emoji\"",
            "\"Segoe UI Symbol\"",
        ].join(","),
    },
    palette: {
        mode: "dark",
        background: {
            default: backgroundColor,
            paper: backgroundColor,
        },
        cardBorder: cardBorderColor,
        highlighted: highlightedColor,
        title: titleColor,
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    margin: "1rem",
                    borderColor: cardBorderColor.main,
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    textAlign: "center",
                    padding: "1rem",
                    "&:last-child": { paddingBottom: "1rem"},
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: titleColor.main,
                }
            }
        },
    }
})
