import {Send} from "@mui/icons-material"
import {LoadingButton} from "@mui/lab"
import {Alert, Card, CardContent, Grid, Stack, TextField, Typography} from "@mui/material"
import React, {useState} from "react"
import {MessageRequest, sendMessage} from "../util/MessageUtils"
import {isAnyBlank, isAnyDefined} from "../util/StringUtils"

type ValueAndError = {value: string, error?: string}

export function ContactUsSection() {
    const [email, setEmail] = useState<ValueAndError>({value: ""})
    const [subject, setSubject] = useState<ValueAndError>({value: ""})
    const [message, setMessage] = useState<ValueAndError>({value: ""})
    const [messageDeliveryInProgress, setMessageDeliveryInProgress] = useState(false)
    const [messageDeliveryError, setMessageDeliveryError] = useState<string | null>()

    const formFilledProperly = isFormFilledProperly()

    return (
        <Grid container flexDirection="column" alignItems="center" textAlign="center">
            <Typography variant="h4" gutterBottom>Contact us</Typography>

            <Typography gutterBottom>
                Want to share some words of love?
                <br/>
                Have an idea for an awesome feature?
                <br/>
                Found a bug?
                <br/>
            </Typography>

            <Typography color="title.main" paddingY="0.5em" fontWeight="bold" gutterBottom>
                Send us a message.
            </Typography>

            <Grid container justifyContent="center">
                <Card variant="outlined" sx={{maxWidth: "30em", flex: 1}}>
                    <CardContent>
                        <Stack component="form" spacing={2} onSubmit={handleSubmit}>
                            <TextField name="email" label="Your e-mail address" size="small"
                                       value={email?.value} onChange={handleEmailChange}
                                       disabled={messageDeliveryInProgress}
                                       error={email?.error !== undefined} helperText={email?.error} fullWidth/>
                            <TextField name="subject" label="Subject" size="small"
                                       value={subject?.value} onChange={handleSubjectChange}
                                       disabled={messageDeliveryInProgress}
                                       error={subject?.error !== undefined}  helperText={subject?.error}/>
                            <TextField name="message" label="Message" size="small" multiline rows="8"
                                       value={message?.value} onChange={handleMessageChange}
                                       disabled={messageDeliveryInProgress}
                                       error={message?.error !== undefined} helperText={message?.error}/>

                            <LoadingButton variant="contained" type="submit"
                                           loading={messageDeliveryInProgress} loadingPosition="end"
                                           endIcon={<Send/>}
                                           disabled={!formFilledProperly || messageDeliveryInProgress}>
                                Send the message
                            </LoadingButton>

                            {
                                messageDeliveryError === null && <Alert severity="success">
                                    The message has been sent successfully
                                </Alert>
                            }
                            {
                                messageDeliveryError && <Alert severity="error">
                                    {messageDeliveryError}
                                </Alert>
                            }
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        const email = event.target.value
        const error = !email || /\S+@\S+\.\S+/.test(email) ? undefined : "Invalid email address"

        setEmail({value: email, error: error})
        setMessageDeliveryError(undefined)
    }

    function handleSubjectChange(event: React.ChangeEvent<HTMLInputElement>) {
        const subject = event.target.value
        const error = !subject || subject.trim().length >= 6 ? undefined : "Subject too short (6 characters are a minimum)"

        setSubject({value: subject, error: error})
        setMessageDeliveryError(undefined)
    }

    function handleMessageChange(event: React.ChangeEvent<HTMLInputElement>) {
        const message = event.target.value
        const error = !message || message.trim().length >= 10 ? undefined : "Message too short (10 characters are a minimum)"

        setMessage({value: message, error: error})
        setMessageDeliveryError(undefined)
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (!formFilledProperly) {
            return
        }

        await deliverMessage()
    }

    async function deliverMessage(): Promise<void> {
        const messageRequest = new MessageRequest(email?.value, subject?.value, message?.value)

        try {
            setMessageDeliveryInProgress(true)
            setMessageDeliveryError(undefined)

            await sendMessage(messageRequest)

            setMessageDeliveryError(null)
        } catch(e: any) {
            console.error(e)
            setMessageDeliveryError(e.message)
        } finally {
            setMessageDeliveryInProgress(false)
        }
    }

    function isFormFilledProperly(): boolean {
        const hasErrors = isAnyDefined(email?.error, subject?.error, message?.error)
        const hasBlankFields = isAnyBlank(email.value, subject.value, message.value)

        return !hasErrors && !hasBlankFields
    }
}

