import {Card, CardContent, Grid, List, ListItem, ListItemText, Typography} from "@mui/material"
import React from "react"

export const FAQSection = () => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center">
        <Grid item>
            <Typography variant="h4" gutterBottom>Frequently Asked Questions</Typography>

            <Typography gutterBottom>
                Sometimes, macOS struggles to deliver notifications, vital part of the StandUp application.
                Read on to find out common issues and how to fix them.
            </Typography>

            <Grid container flexDirection="column" alignItems="center" textAlign="center">
                <Grid item>
                    <Card variant="outlined" sx={{maxWidth: "48rem"}}>
                        <CardContent>
                            <List>
                                <ListItem><ListItemText>
                                    <Typography variant="h6" color="highlighted.main">Focus it turned on</Typography>
                                    <Typography>
                                        macOS 12 Monterey and newer have got a <Typography component="span" color="title.main">Focus</Typography> feature.
                                        Make sure it's turned off. If you want notifications to appear even during Focus, add StandUp App to
                                        the list of <Typography component="span" color="title.main">Allowed Apps</Typography> in
                                        the <Typography component="span" color="title.main">macOS System Settings &#8674; Focus</Typography>.
                                    </Typography>
                                </ListItemText></ListItem>

                                <ListItem><ListItemText>
                                    <Typography variant="h6" color="highlighted.main">Do Not Disturb mode is turned on</Typography>
                                    <Typography>
                                        macOS 11 Big Sur and older have got a <Typography component="span" color="title.main">Do Not Disturb</Typography> feature.
                                        Make sure it's turned off in the <Typography component="span" color="title.main">macOS System Settings &#8674; Notifications &#8674; Do Not Disturb</Typography>.
                                    </Typography>
                                </ListItemText></ListItem>

                                <ListItem><ListItemText>
                                    <Typography variant="h6" color="highlighted.main">Notifications are disabled</Typography>
                                    <Typography>
                                        Go to <Typography component="span" color="title.main">macOS System Settings &#8674; Notifications</Typography>,
                                        click the <Typography component="span" color="title.main">StandUp</Typography> App, and enable notifications.
                                    </Typography>
                                </ListItemText></ListItem>

                                <ListItem><ListItemText>
                                    <Typography variant="h6" color="highlighted.main">Notification settings are not working</Typography>
                                    <Typography gutterBottom>
                                        Sometimes, macOS notification settings stop working. Deleting them and re-approving StandUp notifications often fixes the problem.
                                    </Typography>
                                    <Typography gutterBottom>
                                        Once deleted, StandUp will immediately ask for permissions to send notifications.
                                        Click the <Typography component="span" color="title.main">Allow</Typography> button.
                                    </Typography>
                                    <Typography gutterBottom>
                                        In macOS 12 Monterey and newer, go to <Typography component="span" color="title.main">macOS System Settings &#8674; Notifications</Typography>,
                                        right-click the <Typography component="span" color="title.main">StandUp</Typography> App, and click the <Typography component="span" color="title.main">Reset Notifications</Typography> button.
                                    </Typography>
                                    <Typography>
                                        In macOS 11 Big Sur and older, go to <Typography component="span" color="title.main">macOS System Settings &#8674; Notifications</Typography>,
                                        click the <Typography component="span" color="title.main">StandUp</Typography> App,
                                        and press the <Typography component="span" color="title.main">&#9003; delete</Typography> key.
                                    </Typography>
                                </ListItemText></ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)
