import {Card, CardContent, Grid, Link, Typography} from "@mui/material"
import React from "react"
import appIcon from "../image/AppIcon.svg"

export const MotivationSection = (
    props: {
        appInfoId: string,
        adviceId: string,
    }
) => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center">
        <Grid item>
            <Typography variant="h4" gutterBottom>There is hope!</Typography>

            <Typography gutterBottom>
                It's important to realise that the only one who can really help you is <Typography component="span" color="title.main">you</Typography>.
                You're the only one who decides how you treat your body.
            </Typography>
            <Typography gutterBottom>
                If you have no back issues yet,
                listening to our <Link href={`#${props.adviceId}`}>advice</Link> will help you keep your spine healthy.
            </Typography>
            <Typography gutterBottom>
                If you already have back issues, sticking to our advice is essential to prevent any spine flare-ups.
            </Typography>
        </Grid>

        <Grid item>
            <Card variant="outlined" sx={{maxWidth: "24rem"}}>
                <CardContent>
                    We wish you a healthy spine,
                    and hope our <Link href={`#${props.appInfoId}`} sx={{whiteSpace: "nowrap"}}>
                        <img src={appIcon} alt="StandUp for macOS" width="16px" height="16px"
                             style={{marginRight: "0.3rem", verticalAlign: "middle"}}/>StandUp App
                    </Link> together with the information here will help to improve your working habits to keep your body healthy.
                </CardContent>
            </Card>
        </Grid>
    </Grid>
)
