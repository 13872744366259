import {CssBaseline, ThemeProvider} from "@mui/material"
import React from "react"
import ReactDOM from "react-dom/client"
import {App} from "./App"
import {globalTheme} from "./globalTheme"

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
)

root.render(
    <React.StrictMode>
        <ThemeProvider theme={globalTheme}>
            <CssBaseline enableColorScheme/>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
)
