import {Card, CardContent, CardHeader, Typography} from "@mui/material"
import React from "react"

export function InfoCard(
    props: {
        id?: string,
        titleIcon: string,
        titleText: string,
        children: [string, JSX.Element][], // an array of tuples
    }
) {
    return (
        <Card variant="outlined" sx={{maxWidth: "24rem"}}>
            <CardHeader title={
                <>
                    <img src={props.titleIcon} alt={props.titleText} width="24px" height="24px"
                         style={{marginRight: "0.5em", verticalAlign: "text-bottom"}}/>
                    {props.titleText}
                </>
            } sx={{padding: "0.5rem", color: "highlighted.main", borderBottom: "1px solid", borderColor: "cardBorder.main"}}/>

            <CardContent>
                {
                    props.children.map(([title, element], index) => {
                        return (<Typography component="div" key={index} textAlign="left" marginBottom="1rem" sx={{"&:last-child": {marginBottom: 0}}}>
                            <Typography variant="h6" color="title.main">
                                {title}
                            </Typography>
                            {/* Wrap the element in <div> to prevent having nested <p> elements */}
                            <Typography component="div">
                                {element}
                            </Typography>
                        </Typography>)
                    })
                }
            </CardContent>
        </Card>
    )
}
