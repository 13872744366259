import {Card, CardContent, Grid, List, ListItem, ListItemText, Typography} from "@mui/material"
import React from "react"
import {ImageCard} from "../component/ImageCard"
import spineDetailImage from "../image/SpineDetail.svg"
import spineDetailSlouchedImage from "../image/SpineDetailSlouched.svg"
import spineDetailStraightImage from "../image/SpineDetailStraight.svg"

export const SpineDetailsSection = () => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center" spacing="2rem">

        {/* Each grid item has spacing around as defined in the parent. */}
        {/* Hence the nested column grids to horizontally align texts and cards. */}
        <Grid item>
            <Grid container flexDirection="column" alignItems="center" textAlign="center">
                <Grid item>
                    <Typography variant="h4" gutterBottom>More about intervertebral discs</Typography>

                    <Typography gutterBottom>
                        There is a disc in between each vertebra.
                        Discs are cushions for your spine, absorbing shocks and giving your spine flexibility.
                    </Typography>

                    <Typography gutterBottom>
                        The discs consist of an outer layer
                        called <Typography component="span" color="title.main">anulus fibrosus</Typography> (a strong fibre cartilage),
                        and an inner gelly-like core
                        called <Typography component="span" color="title.main">nucleus pulposus</Typography> (a shock absorber).
                    </Typography>
                </Grid>

                <Grid item>
                    <ImageCard title="A disc between two vertebrae" imageSrc={spineDetailImage} imageAlt="Spine" imagePadding={true}/>
                </Grid>

                <Grid item>
                    <Typography gutterBottom>
                        While muscles get tired and you feel that strain, cartilages do not offer such a feedback luxury.
                        That's because the spine cartilages do not contain nerves. You can't feel their overstrain.
                        And that's the most dangerous thing about them.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

        <Grid item>
            <Typography variant="h5" gutterBottom>What do cartilages need</Typography>

            <Typography gutterBottom>
                Cartilages need movement to regenerate. A lot of movement.
                Movement is the only way for cartilage to absorb nutrients from surrounding tissues,
                because they do not contain blood vessels.
            </Typography>
        </Grid>

        <Grid item>
            <Grid container flexDirection="column" alignItems="center" textAlign="center">
                <Grid item>
                    <Typography variant="h5" gutterBottom>Disc degeneration</Typography>
                </Grid>

                <Grid item>
                    <Card variant="outlined" sx={{maxWidth: "24rem"}}>
                        <CardContent>
                            <List>
                                <ListItem><ListItemText>
                                    Permanent static pressure on the cartilage results in tear development.
                                </ListItemText></ListItem>
                                <ListItem><ListItemText>
                                    Lack of movement prevents the discs to absorb nutrients.
                                    Discs <Typography component="span" color="title.main">degenerate</Typography> and <Typography component="span" color="title.main">do not regenerate</Typography>.
                                </ListItemText></ListItem>
                                <ListItem><ListItemText>
                                    Slouched posture pushes the <Typography component="span" color="title.main">nucleus pulposus</Typography> to the back,
                                    making its way through. This is called a <Typography component="span" color="title.main">disc bulge</Typography>.
                                </ListItemText></ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item>
                    <Grid container justifyContent="center">
                        <ImageCard title="Correct posture"  imageSrc={spineDetailStraightImage} imageAlt="Spine detail straight" imagePadding={true}/>
                        <ImageCard title="Slouched posture" imageSrc={spineDetailSlouchedImage} imageAlt="Spine detail slouched" imagePadding={true}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)
