import {Card, CardContent, CardHeader, CardMedia} from "@mui/material"
import React from "react"

export function ImageCard(
    props: {
        id?: string,
        title?: string,
        imageSrc: string,
        imageAlt: string,
        imagePadding: boolean,
        children?: JSX.Element | JSX.Element[],
    }
) {
    return (
        <Card variant="outlined" id={props.id} sx={{maxWidth: "24rem"}}>
            <CardMedia component="img" image={props.imageSrc} alt={props.imageAlt}
                       sx={{padding: props.imagePadding ? "1rem" : 0, paddingBottom: 0}}/>
            {
                props.children ? (
                    <CardContent>
                        {props.children}
                    </CardContent>
                ) : null
            }
            { props.title ? <CardHeader title={props.title}/> : null }
        </Card>
    )
}

ImageCard.defaultProps = {
    imagePadding: false,
}
