import {Card, CardContent, Grid, Link, List, ListItem, ListItemText, Tooltip, Typography} from "@mui/material"
import React from "react"
import appIcon from "../image/AppIcon.svg"

export const AdviceSection = (props: {
    appInfoId: string,
    adviceId: string,
    straightPostureId: string,
}) => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center">
        <Typography variant="h4" gutterBottom>What shall I do?</Typography>
        <Typography gutterBottom>The negative effects of everyday sitting can't be fully compensated. However, you can reduce it at least.</Typography>

        <Card id={props.adviceId} variant="outlined" sx={{maxWidth: "30rem"}}>
            <CardContent>
                <Link href={`#${props.appInfoId}`}>
                    <img src={appIcon} alt="App icon"
                         style={{width: "64px", height: "64px", margin: "1rem", marginBottom: 0}}/>
                </Link>
                <List>
                    <ListItem><ListItemText>
                        Use the <Link href={`#${props.appInfoId}`}>StandUp App</Link> to interrupt your sitting.
                        A quick stretch every 30 minutes would do.
                    </ListItemText></ListItem>
                    <ListItem><ListItemText>
                        Find yourself a decent physical therapist to correct your muscle disbalances.
                    </ListItemText></ListItem>
                    <ListItem><ListItemText>
                        Walk at least 6000 steps a day, which is about an hour of walking,
                        enough to nourish the discs.
                    </ListItemText></ListItem>
                    <ListItem><ListItemText>
                        Keep a <Link href={`#${props.straightPostureId}`}>straight sitting posture</Link>, and change your working
                        position often.
                    </ListItemText></ListItem>
                    <ListItem><ListItemText>
                        There are many options: regular chair, exercise ball,
                        kneeling chair, <Tooltip arrow title="Inflatable sitting ball (TOGU Dynair Ballkissen for example)">
                            <Typography component="span" sx={{borderBottom: "1px dotted"}}>dynamic sitting</Typography>
                        </Tooltip>
                        , standing desk, lying position.
                    </ListItemText></ListItem>
                    <ListItem><ListItemText>
                        <Typography component="span" color="title.main">The rule of thumb:</Typography>&nbsp;
                        If you feel tired and can't keep a straight sitting posture, change your working position.
                    </ListItemText></ListItem>
                </List>
            </CardContent>
        </Card>
    </Grid>
)
