import {Grid, Link, Typography} from "@mui/material"
import React from "react"
import {ImageCard} from "../component/ImageCard"
import appActivityImage from "../image/AppActivity.jpg"
import appIcon from "../image/AppIcon.svg"
import appNotificationImage from "../image/AppNotification.jpg"
import appSettingsImage from "../image/AppSettings.jpg"
import appstoreDownloadImage from "../image/DownloadOnTheMacAppStore.svg"

export const AppInfoSection = (props: {
    appInfoId: string,
}) => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center" spacing="2rem">
        <Grid item>
            <Typography variant="h4" component="h1">
                Stand up and stretch.<br/>Regularly.
            </Typography>
        </Grid>

        <Grid item>
            <ImageCard id={props.appInfoId} imageSrc={appActivityImage} imageAlt="StandUp activity">
                <Typography variant="h5" gutterBottom>
                    <img src={appIcon} alt="StandUp for macOS" width="32px" height="32px"
                        style={{marginRight: "0.5rem", verticalAlign: "bottom"}}/>
                    macOS App
                </Typography>

                <Typography color="title.main" gutterBottom>
                    The desktop app to help you<br/>improve sitting habits.
                </Typography>
                <Typography gutterBottom>
                    A little regular stretch will make your body healthier.
                </Typography>

                <Link href="https://itunes.apple.com/cz/app/standup/id1439378680?mt=12" target="_blank" rel="noreferrer">
                    {/* The `verticalAlign: "middle"` removes an annoying 6px bottom margin of the image when placed in a container. */}
                    {/* See https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image */}
                    <img src={appstoreDownloadImage} alt="Download on the Mac App Store" width="156px" height="40px"
                        style={{verticalAlign: "middle", marginTop: "0.5rem"}}/>
                </Link>
            </ImageCard>
        </Grid>

        <Grid item>
            <Grid container justifyContent="center">
                <Grid item>
                    <ImageCard title="Define intervals" imageSrc={appSettingsImage} imageAlt="StandUp settings"/>
                </Grid>
                <Grid item>
                    <ImageCard title="Stretch regularly" imageSrc={appNotificationImage} imageAlt="StandUp notification"/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)
