import {Grid, Typography} from "@mui/material"
import React from "react"

export const FooterSection = () => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center">
        <Typography variant="h4" gutterBottom>App privacy policy</Typography>

        <Typography gutterBottom>We do not collect any personal data at all.</Typography>

        <Typography component="div" paddingTop="4rem" color="#6c757d">
            <Typography gutterBottom>Copyright © 2018-2023 Pavel Lobodinský.</Typography>
            <Typography gutterBottom>Many thanks to my friends and app users, who provided me with precious feedback. You're the best.</Typography>

            <Typography sx={{marginTop: "2rem", textDecoration: "underline"}}>Special thanks to:</Typography>
            <Typography>Barbora Sokolová, my wonderful physical therapist, for web site fact-checking and endless efforts to get my weak body back into shape.</Typography>
            <Typography>Antonín Grund, my dear friend, for app beta testing, web review and the never-ending flow of brilliant ideas.</Typography>
            <Typography>Jana Volencová, my documentation master, for copy editing.</Typography>
        </Typography>
    </Grid>
)
