import {Grid, Typography} from "@mui/material"
import React from "react"
import {InfoCard} from "../component/InfoCard"
import eyeIcon from "../image/EyeIcon.svg"
import lowerTrunkIcon from "../image/LowerTrunkIcon.svg"
import spineIcon from "../image/SpineIcon.svg"
import upperTrunkIcon from "../image/UpperTrunkIcon.svg"

export const LongTermSittingSection = () => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center">
        <Typography variant="h4" gutterBottom>Long-term sitting consequences</Typography>

        <Grid container justifyContent="center">
            <InfoCard titleIcon={eyeIcon} titleText="Eyes">
                {[
                    ["Near-sightedness",            <Typography>Lenses' muscles get tight from focusing to the display's short distance most of the time.</Typography>],
                    ["Dry eyes and blurred vision", <Typography>Low blinking rate keeps the eyes dry, irritated.</Typography>],
                    ["Headaches, neck pain",        <Typography>Moving the eyes constantly among the keyboard, display, and the documents, makes the eyes' muscles overstrained.</Typography>],
                ]}
            </InfoCard>
            <InfoCard titleIcon={upperTrunkIcon} titleText="Upper trunk">
                {[
                    ["Cervical disc degeneration", (<>
                        <Typography gutterBottom>Forward-head posture overloads the cervical discs, potentially causing disc bulge and nerve irritation.</Typography>
                        <Typography gutterBottom>Headaches, and hands' tingling or sensitivity issues, are the consequences.</Typography>
                    </>)],
                    ["Muscle disbalances", (<>
                        <Typography gutterBottom>Static posture with hands on a keyboard leads to an overload of nape muscles, and weakness of scapulae muscles.</Typography>
                        <Typography gutterBottom>The result is a chronic neck pain, and rounded and elevated shoulders.</Typography>
                    </>)],
                ]}
            </InfoCard>
        </Grid>

        <Grid container justifyContent="center">
            <InfoCard titleIcon={spineIcon} titleText="Low back">
                {[
                    ["Lumbar disc degeneration", (<>
                        <Typography gutterBottom>Slouched sitting position in a chair overloads the intervertebral discs, potentially causing disc bulge and nerve irritation.</Typography>
                        <Typography gutterBottom>Chronic back pain, tingling or sensitivity issues of the feet, are the consequences.</Typography>
                    </>)],
                    ["Bone degeneration", (<>
                        <Typography gutterBottom>Overloaded discs dehydrate, crack, and degenerate. Worn out joints and inflammation makes the body react by changing the bone structure.</Typography>
                        <Typography gutterBottom>Bones get weak, and spine gets less flexible, resulting in more pain.</Typography>
                    </>)],
                    ["Weak deep abdominal muscles", (<>
                        <Typography gutterBottom>Unused muscles are weak, providing no spine support.</Typography>
                        <Typography gutterBottom>The lumbar spine is vulnerable when lifting weights for example.</Typography>
                    </>)],
                ]}
            </InfoCard>
            <InfoCard titleIcon={lowerTrunkIcon} titleText="Lower trunk and limb">
                {[
                    ["Muscle disbalances", (<>
                        <Typography gutterBottom>Short psoas muscles drag the lumbar spine forward. Short hamstrings tilt the pelvis backward.</Typography>
                        <Typography gutterBottom>Together with weak glutes, the pelvis and thus the spine, is not held in a neutral position.</Typography>
                        <Typography gutterBottom>Body flexibility is reduced, overstraining the spine.</Typography>
                    </>)],
                    ["Knee issues", <Typography>Cartilages need movement to absorb nutrients and regenerate. Sitting causes knee degeneration.</Typography>],
                    ["Poor blood circulation", (<>
                        <Typography gutterBottom>Sitting puts pressure on the blood vessels, reducing the blood flow.</Typography>
                        <Typography gutterBottom>Legs do not get enough oxygen and nutrients.</Typography>
                    </>)],
                ]}
            </InfoCard>
        </Grid>
    </Grid>
)
