import {Grid, Typography} from "@mui/material"
import React from "react"
import {ImageCard} from "../component/ImageCard"
import sittingSlouchedImage from "../image/SittingSlouched.svg"
import sittingStraightImage from "../image/SittingStraight.svg"

export const SittingIssueSection = (props: {
    straightPostureId: string,
}) => (
    <Grid container flexDirection="column" alignItems="center" textAlign="center">
        <Typography variant="h4" gutterBottom>
            What's wrong with sitting?
        </Typography>
        <Typography gutterBottom>
            While sitting, try to hold your spine straight for only 10 minutes.
            It is likely that you will relax and slouch soon, as your muscles can't withstand such load.
        </Typography>

        <Grid container justifyContent="center">
            <Grid item>
                <ImageCard id={props.straightPostureId} title="Correct posture"
                           imageSrc={sittingStraightImage} imageAlt="Straight posture" imagePadding={true}/>
            </Grid>
            <Grid item>
                <ImageCard title="Slouched posture" imageSrc={sittingSlouchedImage} imageAlt="Slouched posture" imagePadding={true}/>
            </Grid>
        </Grid>

        <Typography variant="h6" color="title.main" gutterBottom>
            What happens when you sit all day long for many years?
        </Typography>
        <Typography gutterBottom>
            Your tired muscles relax, and the body weight is carried by the spine,
            putting all the pressure to the intervertebral discs and ligaments.
            The discs and ligaments are overloaded, and start to degenerate.
        </Typography>
        <Typography gutterBottom>
            Some muscles and ligaments shorten, some get tight.
            Muscle disbalances develop, forcing you to stand, walk, move, and exercise incorrectly.
        </Typography>
    </Grid>
)
