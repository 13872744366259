const messageServerUrl = `${process.env.PUBLIC_URL}/api/message`

export class MessageRequest {
    constructor(
        readonly email  : string,
        readonly subject: string,
        readonly message: string,
    ) {}
}

export async function sendMessage(emailRequest: MessageRequest): Promise<void> {
    let response
    let responseBody

    try {
        response = await fetch(messageServerUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/plain",
            },
            body: JSON.stringify(emailRequest)
        })

        responseBody = await response.text()
    } catch (e: any) {
        console.error(e)
        throw new Error("Failed to send the message due to a network error")
    }

    if (!response.ok) {
        throw new Error(responseBody)
    }
}
